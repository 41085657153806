#loginContainer {
  background-image: linear-gradient(
  230deg,
  hsl(270deg 100% 7%) 0%,
  hsl(237deg 45% 13%) 6%,
  hsl(223deg 49% 17%) 12%,
  hsl(215deg 51% 20%) 18%,
  hsl(209deg 51% 23%) 24%,
  hsl(204deg 48% 27%) 29%,
  hsl(200deg 44% 31%) 35%,
  hsl(197deg 39% 35%) 41%,
  hsl(193deg 34% 40%) 47%,
  hsl(189deg 28% 44%) 53%,
  hsl(185deg 23% 49%) 59%,
  hsl(179deg 22% 55%) 65%,
  hsl(172deg 24% 61%) 71%,
  hsl(164deg 27% 68%) 76%,
  hsl(154deg 30% 75%) 82%,
  hsl(142deg 35% 82%) 88%,
  hsl(125deg 46% 89%) 94%,
  hsl(104deg 100% 95%) 100%
);

}

#usernameText {
  text-shadow: 1px 0px 3px #ef3550,
  2px 0px 7px #f48fb1,
  3px 0px 7px #7e57c2,
  4px 0px 7px #2196f3,
  5px 0px 7px #26c6da;
}